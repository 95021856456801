<template>
  <section>
    <template v-if="!submited">
      <rj-feedback-form
        :question="funnel.surveyQuestionYesResponse"
        :survey-id="survey.id"
        @submited="submited = true"
      />
    </template>

    <template v-else>
      <vx-card class="py-2">
        <div class="text-center">
          <h1>{{ funnel.photoRequestHeading }}</h1>
          <p class="mt-3 text-lg">
            {{ funnel.photoRequestContent }}
          </p>
        </div>

        <div class="mt-5">
          <rj-contact-avatar-uploader
            :contact="contact"
            :survey="survey"
            @submited="complete"
          />
        </div>
      </vx-card>
    </template>
  </section>
</template>

<script>
import RjFeedbackForm from '@/views/common/RjFeedbackForm.vue';
import RjContactAvatarUploader from '@/views/common/RjContactAvatarUploader.vue';

export default {
  name: 'RjPositiveTestimonial',

  components: {
    RjFeedbackForm,
    RjContactAvatarUploader,
  },

  props: {
    company: {
      type: Object,
      required: true,
    },

    survey: {
      type: Object,
      required: true,
    },

    contact: {
      type: Object,
      required: true,
    },

    funnel: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      submited: false,
    };
  },

  methods: {
    complete() {
      this.$router.replace({ name: 'completed-testimonial' });
    },
  },
};
</script>

