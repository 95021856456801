<template>
  <div>
    <div
      class="relative h-32 w-32 flex items-center justify-center rounded-full mx-auto overflow-hidden"
      :class="{ 'bg-primary': !avatar }"
    >
      <img
        :src="avatar || dummy"
        class="object-cover"
        :class="{ 'h-32 w-32 rounded-full': avatar, 'w-12': !avatar }"
      >
      <!-- <div class="hidden group-hover:flex items-center justify-center absolute inset-0 w-full h-full bg-gray-800 bg-opacity-75 cursor-pointer">
        <feather-icon
          icon="CameraIcon"
          svg-classes="w-5 h-5 text-white"
          @click.prevent="browse"
        />
      </div> -->
      <input
        ref="uploader"
        type="file"
        class="hidden"
        @change="preview"
      >
    </div>

    <div class="flex justify-center space-x-4 mt-5">
      <vs-button
        v-if="hasAvatar"
        :color="colors.rjGray500"
        :text-color="colors.rjGray500"
        type="border"
        @click="cancel"
      >
        Cancel
      </vs-button>
      <vs-button
        v-if="!uploading && !hasAvatar"
        :color="colors.rjGray500"
        :text-color="colors.rjGray500"
        type="border"
        @click="skip"
      >
        No Thanks
      </vs-button>
      <vs-button
        v-if="!hasAvatar"
        color="success"
        @click="browse"
      >
        Upload
      </vs-button>
      <vs-button
        v-else
        color="success"
        :disabled="uploading"
        @click="save"
      >
        {{ !uploading ? 'Save' : 'Saving...' }}
      </vs-button>
    </div>
  </div>
</template>

<script>
import { http } from '@/ky';
import { colors } from '@/../themeConfig';

export default {
  name: 'RjContactAvatarUploader',

  props: {
    contact: {
      type: Object,
      required: true,
    },

    survey: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      file: null,
      avatar: null,
      uploading: false,
    };
  },

  computed: {
    colors() {
      return colors;
    },

    hasAvatar() {
      return this.avatar !== null;
    },

    dummy() {
      return require('@/assets/images/default-avatar.png');
    },
  },

  methods: {
    async preview() {
      await this.$nextTick();

      [this.file] = this.$refs.uploader.files;
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this.avatar = reader.result;
      });

      reader.readAsDataURL(this.file);
    },

    async save() {
      try {
        this.uploading = true;

        const formData = new FormData();
        formData.append('surveyId', this.survey.id);
        formData.append('AVATAR_FILE', this.file);

        await http.post(`contacts/${this.contact.id}/uploadAvatar`, { body: formData });
        this.$emit('submited');
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      } finally {
        this.file = null;
        this.avatar = null;
        this.uploading = false;
      }
    },

    cancel() {
      this.file = null;
      this.avatar = null;
    },

    skip() {
      this.$emit('submited');
    },

    browse() {
      this.$refs.uploader.click();
    },
  },
};
</script>
